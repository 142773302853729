@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;0,900;1,800&display=swap');

@import '../src/assets/scss/colours';
@import '../src/assets/scss/buttons.scss';
@import '../src/assets/scss/base';
@import '../src/assets/scss/mixins';
@import '../src/assets/scss/variables';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '../src/assets/scss/tagify.scss';

html, body { height: 100%; }
body { margin: 0;}
