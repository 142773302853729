@import "./colours";

body {
  margin: 0;
  overflow-x: hidden !important;
  font-family: "Montserrat", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
}

a {
  text-decoration: none;
}

.input-box {
  border: 1px solid #e7e7e7;
  padding: 10px;
  font-size: 15px;
  width: 100%;
  border-radius: 5px;
}

.form-control {
  font-size: 15px;
  border: 1px solid #e7e7e7 !important;
  height: 45px;
}

textarea:focus,
select,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: none;
  box-shadow: none;
  outline: 0 none;
}

.form-control:focus {
  border-color: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.main-header-space {
  position: relative;
}

.second-header {
  position: relative;
  top: 2rem;
}

.loading {
  width: 200px;
  display: block;
  margin: auto;
}

.no-data {
  width: 150px;
  display: block;
  margin: auto;
  img {
    width: 100%;
  }
  p {
    font-size: 14px;
    text-align: center;
    padding: 15px 0;
  }
}

.content-loading {
  width: 300px;
  display: block;
  margin: auto;
}

.disable-opacity {
  opacity: 0.5;
  pointer-events: none;
}

.back-btn {
  position: fixed;
  top: 20px;
  left: 15px;
  background: #cccccc29;
  padding: 5px 20px;
  color: black;
  border-radius: 25px;
}

.plain-card {
  border-radius: 7px;
  border: none;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  padding: 25px;
}

.admin-card {
  border-radius: 7px;
  border: none;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  padding: 15px;
}

.custom-dropdown {
  position: relative;
  width: 100%;
  // height: 80px;
  box-shadow: 0 5px 15px 0 rgb(144 147 151 / 30%);
  border-radius: 7px;
  background-color: $white;
  border: 0;
  padding: 25px 30px 25px 30px;
  font-size: 1rem;
  letter-spacing: 0.1px;
  color: $lightBlack;
  i {
    font-size: 1rem;
    color: $lightBlack;
    &.arrow {
      position: absolute;
      top: 30px;
      right: 1rem;
    }
  }
  p {
    cursor: pointer;
  }
  .options {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: white;
    border-radius: 7px;
    box-shadow: 0 5px 15px 0 rgb(144 147 151 / 20%);
    display: none;
    z-index: 1;
    &.show {
      display: block;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        cursor: pointer;
        padding: 10px;
        &:hover {
          background: $tripleC;
        }
      }
    }
  }
}

.all-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button {
  &:focus {
    border-color: none;
    box-shadow: none;
    outline: 0 none;
  }
}

.data-content {
  white-space: pre-line;
}